import { IResponseData, IPrice, IOrderModuleData } from '../stores/types';
import { PiwikEvent, MetaEvent } from './trackingHelper.d';

// eslint-disable-next-line no-var, @typescript-eslint/no-explicit-any
declare var _paq: any;
// eslint-disable-next-line no-var, @typescript-eslint/no-explicit-any
declare var _fbq: any;

function getPriceValue(price: IPrice) {
    return price.grossValue;
}

class TrackingHelper {
    private piwikProEnableEcommerceTracking: boolean;
    private enableMetaPixel: boolean;
    private esBrand: string;
    private itemOrigin: string;

    constructor(piwikProEnableEcommerceTracking: boolean, enableMetaPixel, esBrand: string, itemOrigin: string) {
        this.piwikProEnableEcommerceTracking = piwikProEnableEcommerceTracking;
        this.enableMetaPixel = enableMetaPixel;
        this.esBrand = esBrand;
        this.itemOrigin = itemOrigin;
    }

    public piwikTrackVisitedArticle(data: IResponseData) {
        if (!this.piwikProEnableEcommerceTracking)
            return;

        const trackedData = [
            {
                sku: data.orderModule.articleInfo.salesArticleNo,
                name: data.orderModule.articleInfo.title,
                category: data.breadcrumb.category.navigationKeyPath.split('/'),
                price: data.orderModule.articlePrice.basicPrice.netValue,
                brand: (data.orderModule.articleInfo?.brand ?? '').toLocaleLowerCase() === this.esBrand ?
                    'Strauss' : 'other Brand',
                variant: data.orderModule.articleInfo.salesArticleVariantKey,
                customDimensions: {
                    '1': data.orderModule.articleInfo.masterArticleNo.toString(),
                    '2': data.variantSelection.selectedColor?.code ?
                        data.variantSelection.selectedColor.code.toString(): '',
                    '3': data.variantSelection.selectedSize?.name,
                    '4': data.variantSelection.selectedModel?.code ?
                        data.variantSelection.selectedModel.code.toString() : '',
                    '5': 'MODAL_ORDER/' + this.itemOrigin,
                }
            }
        ];

        if (typeof _paq !== 'undefined')
            _paq.push([PiwikEvent.EcommerceProductDetailView, trackedData]);
    }

    public metaTrackVisitedArticle(data: IResponseData) {
        if (!this.enableMetaPixel)
            return;

        const trackedData = [
            {
                content_name: data.orderModule.articleInfo.title,
                content_category: data.breadcrumb.category.navigationKeyPath.split('/'),
                content_ids: [data.orderModule.articleInfo.salesArticleVariantKey],
                content_type: 'product',
                value: getPriceValue(data.orderModule.articlePrice.basicPrice),
                currency: data.orderModule.articlePrice.currency.isoCode,
            }
        ];

        if (typeof _fbq !== 'undefined')
            _fbq.push(['track', MetaEvent.ViewContent, trackedData]);
    }

    public metaTrackAddToCart(orderModule: IOrderModuleData, quantity: number) {
        if (!this.enableMetaPixel)
            return;

        let price = getPriceValue(orderModule.articlePrice.basicPrice);
        if (orderModule.articlePrice.scalePrices && orderModule.articlePrice.scalePrices.length > 0) {
            const scalePrices = orderModule.articlePrice.scalePrices.slice();
            scalePrices.sort((a, b) => {
                let result = 0;
                if (a.quantity < b.quantity) {
                    result = -1;
                } else if (a.quantity > b.quantity) {
                    result = 1;
                }
                return result;
            });

            for (let i = 0; i < scalePrices.length; i++) {
                if (quantity >= scalePrices[i].quantity) {
                    price =  getPriceValue(scalePrices[i].price);
                }
            }
        }
        const value = price * quantity;

        const trackedData = [
            {
                contents: [
                    {
                        id : orderModule.articleInfo.salesArticleVariantKey,
                        quantity,
                    }
                ],
                content_type: 'product',
                value,
                currency: orderModule.articlePrice.currency.isoCode,
            }
        ];

        if (typeof _fbq !== 'undefined')
            _fbq.push(['track', MetaEvent.AddToCart, trackedData]);
    }
}

export default TrackingHelper;
