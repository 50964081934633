import React from 'react';
import { isTablet } from 'react-device-detect';
import classNames from 'classnames';
import { configure } from 'mobx';
import { inject, observer } from 'mobx-react';
import styles from './orderModalContent.scss';
import { IOrderModalContentProps } from '../../../types';
import CloseButton from '../closeButton/closeButton';
import ArticleNumber from '../articleNumber/articleNumber';
import ArticlePrice from '../articlePrice/articlePrice';
import DeliveryTime from '../deliveryTime/deliveryTime';
import BasketPanel from '../basketPanel/basketPanel';
import CompareShareBookmark from '../compareShareBookmark/compareShareBookmark';
import CompareShareBookmarkMobile from '../compareShareBookmarkMobile/compareShareBookmarkMobile';
import MediaFrame from '../mediaFrame/mediaFrame';
import { ColorVariant } from '../variantSelection/colorVariant/colorVariant';
import { SizeVariant } from '../variantSelection/sizeVariant/sizeVariant';
import { ModelVariant } from '../variantSelection/modelVariant/modelVariant';
import { SpecialPanel } from '../variantSelection/specialVariant/specialPanel';
import ToDetailsPageButton from '../toDetailsPageButton/toDetailsPageButton';
import { EyeCatcher } from '../eyeCatcher/eyeCatcher';
import { SalesFlag } from '../salesFlag/salesFlag';
import { SalesTeaser } from '../salesTeaser/salesTeaser';
import { FitAccuracy } from '../variantSelection/fitAccuracy/fitAccuracy';
import ExtContent from '../extContent/extContent';

configure({ enforceActions: 'observed', isolateGlobalState: true, useProxies: 'always' });

const OrderModalContent = inject('store')(
    observer(({ store, slidingOut, handleIsOpenChange }: IOrderModalContentProps) => {
        const variantSelection = store.dataStore.variantSelection;
        const extContentContainerFound = !!store.orderModuleStore.extContentContainer;
        const [hasExtContent, setHasExtContent] = React.useState(false);
        const contentClassNames = classNames(styles.order_modal_content,
            {
                [styles.has_ext_content]: hasExtContent,
                [styles.viewtype_sidepanel]: store.isSidepanel,
                [styles.sliding_out]: slidingOut,
            });

        const showExtContentOnLeft = !store.getIsMobileView && !isTablet;
        const showExtContentAboveVariants = store.getIsMobileView || isTablet;

        return (
            <div className={contentClassNames}>
                <div data-testid='order_modal_content' className={styles.modal_inner_container}>
                    {extContentContainerFound && showExtContentOnLeft &&
                        <div className={styles.ext_content_container}>
                            <ExtContent onHasExtContent={setHasExtContent}/>
                        </div>
                    }
                    {showExtContentOnLeft && hasExtContent && <div className={styles.separator} />}
                    {store.getIsMobileView && <CloseButton handleIsOpenChange={handleIsOpenChange} 
                        title={store.localization.buttonClose} />}
                    <div className={styles.product_image}>
                        <EyeCatcher />
                        <MediaFrame />
                        <div className={styles.media_buttons}>
                            {!store.getIsMobileView && (
                                <>
                                    <div className={styles.compare_share_save}>
                                        <CompareShareBookmark />
                                    </div>
                                    <ToDetailsPageButton handleIsOpenChange={handleIsOpenChange} />
                                </>
                            )}
                        </div>
                    </div>
                    {!store.getIsMobileView && <div className={styles.separator} />}

                    <div className={styles.order_module_container}>
                        <div className={styles.scroll_area}>
                            {!store.getIsMobileView &&
                                <CloseButton handleIsOpenChange={handleIsOpenChange} 
                                    title={store.localization.buttonClose}/>}
                            <ArticleNumber />
                            <SalesFlag />
                            <ArticlePrice />
                            <DeliveryTime />
                            {extContentContainerFound && showExtContentAboveVariants &&
                                <div className={styles.ext_content_container}>
                                    <ExtContent onHasExtContent={setHasExtContent}/>
                                </div>
                            }
                            <div className={styles.product_info_and_variants}>
                                {store.variantStore.showColorSwitcher && <ColorVariant />}
                                {variantSelection?.showSizeSwitcher && !variantSelection?.isScrew && <SizeVariant />}
                                {variantSelection?.showSizeSwitcher && variantSelection?.isScrew && <SpecialPanel />}
                                {variantSelection?.showModelSwitcher && <ModelVariant />}
                                {store.isMobileShop && variantSelection?.showSizeSwitcher &&
                                    !variantSelection?.isScrew &&
                                    (variantSelection?.selectedSize.code !== 0) &&
                                    variantSelection?.selectedSize.fittingAccuracy != null && < FitAccuracy />}
                            </div>
                            <SalesTeaser />
                        </div>
                        {!store.getIsMobileView && <BasketPanel handleIsOpenChange={handleIsOpenChange} />}
                    </div>
                </div>
                {store.getIsMobileView && (
                    <>
                        <div className={styles.footer_gradients} />
                        <div className={styles.footer}>
                            <BasketPanel handleIsOpenChange={handleIsOpenChange} />
                            <CompareShareBookmarkMobile />
                            <ToDetailsPageButton handleIsOpenChange={handleIsOpenChange} />
                        </div>
                    </>
                )}
            </div>
        );
    })
);

export default OrderModalContent;
